@mixin transition($place, $time, $ease, $delay) {
	-moz-transition: $place $time $ease $delay;
	-webkit-transition: $place $time $ease $delay;
	-o-transition-property: $place $time $ease $delay;
	-ms-transition-property: $place $time $ease $delay;
	transition: $place $time $ease $delay;
}

@mixin container(){
// 	width: calc(100% - 60px);
	width: calc(100% - 60px);
	max-width: 1276px;
	margin: 0 auto;
	position: relative;
	@media only screen and (min-width: 960px) {
		width: calc(100% - 120px);
	}
}

@mixin flex(){
	display: flex;
	flex-direction: row;
}

@mixin margintopTransStart(){
	margin-top: 100px;
	opacity: 0;
}

@mixin margintopTransEnd(){
	margin-top: 0;
	opacity: 1;
}

@mixin topTransStart(){
	top: 100px;
	opacity: 0;
}

@mixin topTransEnd(){
	top: 0;
	opacity: 1;
}

@mixin topTransOff(){
	top: -100px;
	opacity: 0;
}

@mixin moveOffstage(){
	top: -100%;
	opacity: 0;
}

$blue: #001e60;
$aqua: #00aa8e;
$aquaalt: #008670;
$gray: #666666;
$yellow: #ffdc00;
$purple: #343370;
$green: #235039;
$lightblue: #00bbdc;
$aquaalt2: #00b99e;