@import "../../../resources/scss/variables";

body#mercury .row.first{
	#hero{
		background-color: #b0b0b0;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		margin-bottom: 0em;
		overflow: hidden;
		.container{
			padding: 6.5em 0;
		}
		h1{
			color: white;
			margin-bottom: 0;
		}
		p{
			color: white;
			margin-bottom: 0;
			&.btn{
				a{
					color: white;
					border-color: white;
					&:hover{
						color: black;
						background-color: white;
					}
				}
			}
		}
		&.type2{
			background-color: transparent !important;
			padding-top: 4.65em;
			.container{
				padding: 0;
			}
			.holder{
				max-width: 900px;
				width: 100%;
				padding-bottom: 3.5em;
			}
			h1{
				color: $blue;
				margin: 0;
			}
			p{
				color: black;
				font-size: 1.4em;
				margin: 0.85em 0 0;
				line-height: 1.25em;
				&.btn{
					a{
						color: $blue;
						border-color: $blue;
						&:hover{
							background-color: $blue;
							color: white;
						}
					}
				}
			}
			.img{
				width: 100%;
				img{
					width: 100%;
					max-width: 100%;
					height: auto;
					display: block;
				}
			}
		}
		&.type3{
			.container{
				padding: 9em 0;
			}
			h1{
				position: absolute;
				left: -10000px;
				top: -10000px;
			}
			p{
				a{
					color: white;
				    text-decoration: none;
				    font-size: 1.3em;
				    display: block;
				    margin-top: 1em;
				    &:hover{
					    text-decoration: underline;
				    }
				}
			}
			.video_holder{
				video{
					display: none;
				}
			}
		}
		&.notfound{
			.content{
				display: flex;
				flex-direction: column;
			}
			h1{
				font-size: 12.5em;
				line-height: 1em;
			}
			p{
				font-size: 1.94em;
				font-weight: 400;
				line-height: 1.5em;
				span{
					font-weight: 600;
					font-size: 1.72em;
				}
			}
		}
		@media only screen and (min-width: 960px) {
			&.type1{
				height: 268px;
				.container{
					padding: 0;
				    display: flex;
				    flex-direction: column;
				    flex-wrap: wrap;
				    justify-content: center;
				    height: 100%;
				}
			}
			&.type3{
				height: 100vh;
				overflow: hidden;
				.video_holder{
				    video{
					    display: block;
					    object-fit: cover;
						width: 100vw;
						height: 0;
						position: fixed;
						bottom: 0;
						left: 0;
						@include transition(height, 0.5s, ease-out, 0s);
				    }
			    }
			    img.extraimg{
				    display: none;
			    }
				.container{
					padding: 0;
				    display: flex;
				    flex-direction: column;
				    flex-wrap: wrap;
				    justify-content: center;
				    height: 100%;
				    padding: 6.5em 0;
				}
				p{
					a{
						margin-top: 2.5em;
					}
				}
			}
			&.type2{
				padding-top: 7em;
			}
			&.notfound{
				height: 393px;
				h1{
					margin-right: 39px;
				}
				.content{
					flex-direction: row;
					align-items: center;
				}
			}	
		}
	}
}